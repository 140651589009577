import { blogAppDefId } from '../constants/apps';

const TOKEN = 'migration-token';

export const getComponentRef = async (sdk, widgetId) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(TOKEN, blogAppDefId);
  if (!blogAppData) {
    return;
  }

  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(TOKEN, blogAppData.applicationId);
  if (!blogAppComponents) {
    return;
  }

  const component = blogAppComponents.find((_component) => _component.widgetId === widgetId);
  if (!component) {
    return;
  }

  const componentRef = await sdk.document.components.getById(TOKEN, { id: component.id });
  return componentRef;
};
